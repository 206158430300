export const ANTI_FRAUD_MODE = {
  none: "none",
  localAuthBioSetup: "local_auth_bio_setup",
  profileImageSetup: "profile_image_setup",
  localAuthBio: "local_auth_bio",
  profileImage: "profile_image",
};

export const ANTI_FRAUD_RESULT = {
  none: "none",
  localAuthBio: "local_auth_bio",
  profileImage: "profile_image",
  localAuthBioIsRefused: "local_auth_bio_is_refused",
  localAuthBioIsNotSupported: "local_auth_bio_is_not_supported",
  localAuthBioSetupIsSuccessed: "local_auth_bio_setup_is_successed",
  profileImageSetupIsCanceled: "profile_image_setup_is_canceled",
  bioRefusedImageCanceled: "bio_refused_image_cancelled",
  bioUnsupportedProfileCanceled: "bio_unsupported_image_cancelled",
  bioRefusedProfileSucceed: "bio_refused_image_succeed",
  bioUnsupportedProfileSucceed: "bio_unsupported_image_succeed",
};

export const COMPANY_TYPES = {
  key: "Company type",
  value: {
    b2b: "B2B(Corporate)",
    copay: "Copay",
    b2c: "B2C",
  },
};

export default {
  countries: ["by", "am", "ru", "ua", "lt", "cy"],
  location_statuses: ["off", "on", "nan"],
  currencies: {
    by: "BYN",
    am: "AMD",
    ru: "RUB",
    ua: "UAH",
    lt: "EUR",
    cy: "EUR",
  },
  invoice_modes: ["no", "auto"],
  supported_countries: {
    by: {
      aggregator: "allsports",
      support_phone: "+375445163914",
      cities: [
        "Minsk",
        "Gomel",
        "Brest",
        "Grodno",
        "Rechytsa",
        "Mogilev",
        "Vitebsk",
        "Lida",
        "Baranovichi",
        "Kobrin",
        "Dzierzhinsk",
        "Fanipol",
        "Astravyets",
        "Molodechno",
        "Polotsk",
        "Novopolotsk",
        "Orsha",
        "Barysaw",
        "Stolin",
        "Pinsk",
        "Bobruysk",
        "Mozyr",
        "Salihorsk",
        "Smarhon",
        "Zhlobin",
      ],
      locations: {
        Minsk: ["53.89118", "27.61657"],
        Gomel: ["53.89118", "27.61657"],
        Brest: ["53.89118", "27.61657"],
        Grodno: ["53.89118", "27.61657"],
        Rechytsa: ["52.9505", "31.7797"],
        Mogilev: ["53.89118", "27.61657"],
        Vitebsk: ["53.89118", "27.61657"],
        Lida: ["53.89118", "27.61657"],
        Baranovichi: ["53.89118", "27.61657"],
        Kobrin: ["53.89118", "27.61657"],
        Dzierzhinsk: ["53.89118", "27.61657"],
        Fanipol: ["53.89118", "27.61657"],
        Astravyets: ["53.89118", "27.61657"],
        Molodechno: ["54.307332", "26.838939"],
        Polotsk: ["55.485576", "28.768349"],
        Novopolotsk: ["55.545302", "28.607394"],
        Orsha: ["54.511825", "30.404904"],
        Barysaw: ["54.14", "28.30"],
        Stolin: ["51.892508", "26.835619"],
        Mozyr: ["52.052663", "29.245175"],
      },
      payment_fields: {},
      vat_rate: {
        "NO VAT": "no_vat",
        "20%": "20%",
      },
      lat: "53.89118",
      lng: "27.61657",
      phone_prefix: "+375",
      phone_zeroes: "+375000000000",
      phone_input_regex: "\\+375\\d{9}",
      phone_regex: "\\+?375(\\d{2})(\\d{3})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+375 ($1) $2-$3-$4",
      unn_validation_url: true,
      subscriptions: ["region", "silver", "gold", "platinum"],
      subscriptions_acronyms: {
        region: "R",
        silver: "S",
        gold: "G",
        platinum: "P",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPSR]).*\\1)[GPSR]+$",
      timezones: ["Europe/Minsk"],
      locales: ["ru_BY"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["BY_BYN"],
    },
    am: {
      aggregator: "allsports",
      support_phone: "+375445163914",
      cities: ["Yerevan", "Gyumri", "Vanadzor"],
      locations: {
        Yerevan: ["40.153759", "44.491567"],
        Gyumri: ["40.784412", "43.836218"],
        Vanadzor: ["40.804918", "44.472450"],
      },
      payment_fields: {},
      vat_rate: {
        "NO VAT": "no_vat",
        "20%": "20%",
      },
      lat: "40.1809",
      lng: "44.5215",
      phone_prefix: "+374",
      phone_zeroes: "+37400000000",
      phone_input_regex: "\\+374\\d{8}",
      phone_regex: "\\+?374(\\d{2})(\\d{2})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+374 ($1) $2-$3-$4",
      subscriptions: ["silver", "gold", "platinum", "vip"],
      subscriptions_acronyms: {
        silver: "S",
        gold: "G",
        platinum: "P",
        vip: "V",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPSV]).*\\1)[GPSV]+$",
      timezones: ["Asia/Yerevan"],
      locales: ["hy_AM"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["AM_AMD"],
    },
    ru: {
      aggregator: "allsports",
      support_phone: "+375445163914",
      cities: ["NizhnyNovgorod", "Yekaterinburg"],
      payment_fields: {
        kpp: "",
        corrAccount: "",
        kbk: "00000000000000000130",
      },
      vat_rate: {
        "NO VAT": "no_vat",
        "20%": "20%",
      },
      lat: "56.331932",
      lng: "44.023225",
      phone_prefix: "+7",
      phone_zeroes: "+70000000000",
      phone_input_regex: "\\+7\\d{10}",
      phone_regex: "\\+?7(\\d{3})(\\d{3})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+7 $1 $2-$3-$4",
      subscriptions: ["silver", "gold", "platinum"],
      subscriptions_acronyms: {
        silver: "S",
        gold: "G",
        platinum: "P",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPS]).*\\1)[GPS]+$",
      timezones: [
        "Asia/Sakhalin",
        "Asia/Ust-Nera",
        "Asia/Magadan",
        "Asia/Srednekolymsk",
        "Asia/Kamchatka",
        "Asia/Anadyr",
        "Asia/Yekaterinburg",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Novokuznetsk",
        "Asia/Irkutsk",
        "Asia/Yakutsk",
        "Asia/Vladivostok",
        "Europe/Kaliningrad",
        "Europe/Moscow",
        "Europe/Volgograd",
        "Europe/Samara",
        "Europe/Ulyanovsk",
      ],
      locales: ["ru_RU"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["RU_RUB"],
    },
    ua: {
      aggregator: "allsports",
      support_phone: "+380636205050",
      cities: ["Kiev"],
      payment_fields: {},
      vat_rate: {
        "NO VAT": "no_vat",
        "20%": "20%",
      },
      lat: "50.402398",
      lng: "30.532735",
      phone_prefix: "+380",
      phone_zeroes: "+380000000000",
      phone_input_regex: "\\+380\\d{9}",
      phone_regex: "\\+?380(\\d{2})(\\d{3})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+380 ($1) $2-$3-$4",
      subscriptions: ["silver", "gold", "platinum", "vip"],
      subscriptions_acronyms: {
        silver: "S",
        gold: "G",
        platinum: "P",
        vip: "V",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPSV]).*\\1)[GPSV]+$",
      timezones: ["Europe/Kiev"],
      locales: ["uk_UA"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["UA_UAH"],
    },
    lt: {
      aggregator: "SportBenefit",
      support_phone: "+37067285071",
      cities: ["Vilnius", "Kaunas", "Klaipeda", "Panevezys"],
      locations: {
        Vilnius: ["54.700788", "25.252959"],
        Kaunas: ["54.890241", "23.926753"],
        Klaipeda: ["55.704966", "21.157584"],
        Panevezys: ["55.730672", "24.333020"],
      },
      payment_fields: {
        vat_code: "LT000000000000",
        inn_type: "Įmonės kodas",
      },
      inn_type: {
        "Įmonės kodas": "Įmonės kodas",
        "Verslo liudijimas": "Verslo liudijimas",
        "Individualios veiklos pažymėjimas":
          "Individualios veiklos pažymėjimas",
      },
      vat_rate: {
        "NO VAT": "no_vat",
        "5%": "5%",
        "9%": "9%",
        "21%": "21%",
      },
      lat: "54.412398",
      lng: "25.172735",
      phone_prefix: "+370",
      phone_zeroes: "+37000000000",
      phone_input_regex: "\\+370\\d{8}",
      phone_regex: "\\+?370(\\d{2})(\\d{2})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+370 $1-$2-$3-$4",
      subscriptions: ["gold", "platinum", "vip"],
      subscriptions_acronyms: {
        silver: "S",
        platinum: "P",
        vip: "V",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPV]).*\\1)[GPV]+$",
      timezones: ["Europe/Vilnius"],
      locales: ["lt_LT", "en_LT"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["LT_EUR"],
    },
    cy: {
      aggregator: "SportBenefit",
      support_phone: "+35799800221",
      cities: ["Limassol", "Nicosia", "Larnaca", "Paphos", "Ayia Napa"],
      locations: {
        Limassol: ["34.678620322088484", "33.041173184474545"],
        Nicosia: ["35.141971", "33.349703"],
        Larnaca: ["34.926294", "33.620770"],
        Paphos: ["34.776961", "32.437536"],
        AyiaNapa: ["34.983329", "34.0"],
      },
      payment_fields: {
        vat_id: "12345678A",
        tic: "12345678A",
      },
      vat_rate: {
        "NO VAT": "no_vat",
        "5%": "5%",
        "9%": "9%",
        "19%": "19%",
      },
      lat: "34.678620322088484",
      lng: "33.041173184474545",
      phone_prefix: "+357",
      phone_zeroes: "+35700000000",
      phone_input_regex: "\\+357\\d{8}",
      phone_regex: "\\+?357(\\d{2})(\\d{2})(\\d{2})(\\d{2})/g",
      phone_regex_replace: "+357 $1-$2-$3-$4",
      subscriptions: ["silver", "gold", "platinum"],
      subscriptions_acronyms: {
        silver: "S",
        gold: "G",
        platinum: "P",
      },
      subscriptions_acronyms_regex: "^(?!.*([GPS]).*\\1)[GPS]+$",
      timezones: ["Asia/Nicosia"],
      locales: ["en_CY"],
      // TODO: move pricing policies into the config from backend
      pricing_policies: ["CY_EUR"],
    },
  },
  language: "ru",
  supported_languages: ["ru", "en"],
  unn_validation_url: false,
  supported_attractions: {
    abs_legs: "Abs & Legs",
    aerial_yoga: "Aerial Yoga",
    aerobics: "Aerobics",
    afro_dance: "Afro Dances",
    aqua_aerobic: "Aqua Aerobic",
    archery: "Archery",
    argentina_tango: "Argentina Tango",
    badminton: "Бадминтон",
    ballet: "Ballet",
    beach_tennis: "Beach tennis",
    belly_dance: "Belly Dance",
    bike_rental: "Bike Rental",
    billiard: "Billiard",
    volleyball: "Volleyball",
    bodiflex: "bodiflex",
    body_ballet: "Body Ballet",
    body_pump: "Body pump",
    bosu: "Bosu",
    boxing: "boxing",
    callanetics: "Callanetics",
    capueiro: "Capueiro",
    carting: "Carting",
    catamarans: "Catamarans",
    "climbing wall": "Climbing wall",
    crossfit: "CROSSFIT",
    cycling: "Cycling",
    dance: "Dance",
    dynamic_meditation_osho: "Dynamic meditation (Osho)",
    ems: "EMS training",
    fat_burn: "Fat burn",
    fight_do: "Fight Do",
    fitness: "Fitness",
    football: "Football",
    functional_training: "Functional Training",
    "gym club": "Gym",
    gym_trainer: "Gym with Personal Trainer",
    hatha_yoga: "Hatha yoga",
    horse_riding: "Horse Riding",
    horse_walking: "Horse Walking",
    ice_skating: "Ice Skating",
    jumping: "Jumping",
    kangoo_jumping: "Kangoo Jumping",
    karate: "Karate",
    kayaking: "Kayaking",
    kickboxing: "Kickboxing",
    kimax: "Kimax",
    kung_fu: "Kung Fu",
    "martial arts": "Martial Arts",
    massage: "Massage",
    meditation: "Meditation",
    mini_golf: "Mini Golf",
    golf: "Golf",
    mma: "Mixed Martial Arts",
    muay_thai: "Muay Thai",
    padel_padel_tennis: "Padel (Padel tennis)",
    pilates_reformer: "Pilates Reformer",
    pilates: "Pilates",
    pole_dance: "Pole Dance",
    pool_trainer: "Pool with Personal Trainer",
    qigong: "Qigong",
    roller_blade_rental: "Roller Blade Rental",
    "salt cave": "Salt Cave",
    sauna: "Sauna",
    shaping: "Shaping",
    shooting: "Shooting",
    spa: "СПА",
    spinning: "Spinning",
    squash: "Squash",
    step_aerobics: "Step Aerobics",
    strength_training: "Strength Training",
    stretching: "Stretching",
    strip_dance: "Strip Dance",
    sup_stand_up_paddle: "SUP stand up paddle",
    supboard: "Сапборд",
    swimming_pool: "Swimming Pool",
    table_tennis: "Table Tennis",
    tai_bo: "Tai Bo",
    tai_chi: "Tai Chi",
    tennis: "Tennis",
    trampoline_park: "Trampoline Park",
    trx: "Suspension training (TRX)",
    wakeboard: "Вейкборд",
    winter_sports: "Зимние Виды Спорта",
    workout: "Workout",
    yoga: "Yoga",
    zumba: "Zumba",
    windsurfing: "Windsurfing",
    watersports_fun: "Watersports fun",
    brazilian_jiu_jitsu: "Brazilian Jiu-Jitsu",
    paintball: "Paintball",
    body_combat: "Body Combat",
    kite_surfing: "Kite Surfing",
    wing_foil: "Wing Foil",
    fencing: "Fencing",
    ping_pong: "Ping Pong",
    scuba_diving: "Scuba Diving",
    pedal_sup: "Велосап",
    oxysize: "Оксисайз",
    american_football: "Американский футбол",
    aerostretching: "Аэростретчинг",
    running: "Бег",
    jakuzzi: "Jakuzzi",
    outdoor_pool: "Outdoor Pool",
    indoor_pool: "Indoor Pool",
    steam_bath: "Steam Bath",
  },
  ANTI_FRAUD_MODE,
  ANTI_FRAUD_RESULT,
};
